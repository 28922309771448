import React from "react"

const LayoutHome = ({ children }) => (
  <>
    <div className="page page--home">
      {children}
    </div>
  </>
)

export default LayoutHome