import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/seo"

import Homepage from "../layouts/homepage"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Breadcrumbs from "../components/chrome/breadcrumbs"
import Container from "../layouts/container"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageProfile = () => {
    return (
        <Homepage>
            <SEO title="My profile" />
            <PageHeader headerText="Per ardua ad astra" />

            <Main>

                <Container>
                    <Breadcrumbs title="Profile" path='/profile' />
                </Container>

                <div className="row">
                    <div className="col--full">
                        <Container>
                            <article className="intro">
                                <div className="intro__portrait">
                                    <StaticImage
                                        src="../img/chrome/mood-design_jan.jpg"
                                        alt="Jan Wessel"
                                        layout="constrained"
                                        width={400}
                                        height={400}
                                    />
                                </div>
                                <h1 className="h1">Moin and hello!</h1>
                                <p>
                                    Thanks for stopping by.
                                </p>
                                <p>You're looking at the MVP of mood-design.net.</p>

                                <p>
                                    My name's Jan, I am an allrounder with roots in print. I've worked as frontend developer and product/&nbsp;project manager/ owner incorporating agile methods.
                                    I put my focus on the UX side of things with special interest in Information Architecture, Content and User Interface Design.
                                </p>
                                <p>
                                    I am father to a sweet little girl named Victoria, who has turned the lives of my wonderful wife Irina and mine upside down.
                                </p>
                                <p>
                                    In my spare time I'm taking photos every now and then and work on DIY projects. I publish <Link to="/posts">personal content</Link> on this website for you to enjoy.
                                </p>
                                <p>
                                    This site is an ongoing work-in-progress, based on <a href="https://gatsbyjs.org/" target="_blank" rel="noopener noreferrer">GatsbyJS</a>.
                                </p>
                                <p>
                                    Happy browsing!
                                </p>

                                <aside className="intro__profiles">
                                    <h1 className="h2">My other profiles</h1>
                                    <p>For my business profile please visit <a href="https://www.xing.com/profile/Jan_Wessel" target="_blank" rel="noopener noreferrer">XING</a> or <a href="https://www.linkedin.com/in/jan-wessel-45396230" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
                                    <p>Find my photos on <a href="https://www.instagram.com/mood_design_net/" target="_blank" rel="noopener noreferrer">Instagram</a> or <a href="https://www.flickr.com/photos/mooddesign/" target="_blank" rel="noopener noreferrer">Flickr</a>.</p>
                                </aside>
                            </article>
                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </Homepage>
    )
}

export default PageProfile